/* Website color-pallet:
Primary: #1c1e6c;
Secondary: #21ceb9;; 
secondary-lighter: #90e7dc;

Font sizing: 10 12 14 16 18 20 22 24 26 28 30 32 34 36;

  letter-spacing: -0.5px;
  letter-spacing: -0.75px;


*/

html {
  font-family: "Roboto", sans-serif;
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}
